import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx"; 
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;
    public appPages = [
        
        {
            title: 'Home',
            url: '/home',
            icon: 'home'
        },
        {
            title: 'Posts',
            url: '/inventory-upload',
            icon: 'camera'
        },
        {
            title: 'Categories',
            url: '/inventory-categories',
            icon: 'link'
        },
        {
            title: 'Account',
            url: '/account',
            icon: 'person'
        }
    ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public app: AppService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
        //this.statusBar.styleDefault();
        this.splashScreen.hide();

        //this.statusBar.overlaysWebView(false);
        ////START FCM
        //this.fcm.getToken().then((token) => {
        //    this.app.sysData.firebase = token;
        //}, (err) => {
        //    this.app.sysData.firebase = 'No Token';
        //})

        //this.fcm.onNotification().subscribe(data => {
        //    this.app.pushNotification(data);
        //});

        ////UPDATING TOKEN IF IT EXPIRES
        //this.fcm.onTokenRefresh().subscribe(token => {
        //    this.app.sysData.firebase = token;
        //});
        ////END FCM
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
