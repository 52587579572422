// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    identityPoolId: 'us-west-2:9840c31e-384a-4e6e-ae86-cbfcfc2681ec',
    cognitoClientId: '3hmrtveiou488ttiugu09ms1b2',
    cognitoPoolId: 'us-west-2_tr77QqKjx',
    bucketName: 'biddaddy-content',
    region: 'us-west-2',

    graphqlAPI: {
        awsAppsyncGraphqlEndpoint: 'https://seurauj67baxpgubws3eih55re.appsync-api.us-west-2.amazonaws.com/graphql',
        awsAppsyncRegion: 'us-west-2',
        awsAppsyncAuthenticationType: 'AWS_IAM',
        awsAppsyncApiKey: 'null'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
