import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'folder/:id',
        loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'auction',
        loadChildren: () => import('./auction/auction.module').then(m => m.AuctionPageModule)
    },
    {
        path: 'inventory',
        loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryPageModule)
    },
    {
        path: 'inventory-single/:id',
        loadChildren: () => import('./inventory-single/inventory-single.module').then(m => m.InventorySinglePageModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'inventory-single-media/:id',
        loadChildren: () => import('./inventory-single-media/inventory-single-media.module').then(m => m.InventorySingleMediaPageModule)
    },
    {
        path: 'inventory-compare',
        loadChildren: () => import('./inventory-compare/inventory-compare.module').then(m => m.InventoryComparePageModule)
    },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'account-payments',
    loadChildren: () => import('./account-payments/account-payments.module').then( m => m.AccountPaymentsPageModule)
  },
  {
    path: 'account-locations',
    loadChildren: () => import('./account-locations/account-locations.module').then( m => m.AccountLocationsPageModule)
  },
  {
    path: 'inventory-upload',
    loadChildren: () => import('./inventory-upload/inventory-upload.module').then( m => m.InventoryUploadPageModule)
  },
  {
    path: 'camera',
    loadChildren: () => import('./camera/camera.module').then( m => m.CameraPageModule)
  },
  {
    path: 'camera/:id',
    loadChildren: () => import('./camera/camera.module').then( m => m.CameraPageModule)
  },
  {
    path: 'account-permissions',
    loadChildren: () => import('./account-permissions/account-permissions.module').then( m => m.AccountPermissionsPageModule)
  },
  {
    path: 'inventory-categories',
    loadChildren: () => import('./inventory-categories/inventory-categories.module').then( m => m.InventoryCategoriesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
